import "../assets/sass/search.scss";

import algoliasearch from "algoliasearch/lite";
import { stringifySearch, Theme, ThemeContext } from "assets";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-link";
import { RefinementFilter } from "molecules";
import { ConnectedSearchForm, MainNavBar, SearchResultsList } from "organisms";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useState } from "react";
import {
  Configure,
  connectSearchBox,
  connectStateResults,
  InstantSearch,
} from "react-instantsearch-dom";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const VirtualSearchBox = connectSearchBox(() => null);
const SearchFilters = connectStateResults(({ searchResults }) => {
  const numHits = searchResults?.nbHits;
  const noResults = numHits === 0;
  const containerBgColor = classNames({
    darkBg: noResults,
    greyBg: !noResults,
    "filter-container": true,
  });

  return (
    <div className={containerBgColor}>
      <div className="col-sm-offset-2 col-sm-8">
        {!noResults && (
          <span className="results">
            {numHits} {numHits > 1 ? "RESULTS" : "RESULT"}
          </span>
        )}
        <div className="search-filters">
          <RefinementFilter attribute="pageType" openButtonTitle="Page Type" />
          <RefinementFilter attribute="mediaTags" openButtonTitle="Media" />
        </div>
      </div>
    </div>
  );
});

const SearchPage = ({ location }) => {
  const term = queryString.parse(location.search).term;

  const [query, setQuery] = useState(term || "");

  const onSearch = (searchTerm) => {
    setQuery(searchTerm);
    navigate(stringifySearch(searchTerm));
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    setQuery(suggestion.title);
    navigate(stringifySearch(suggestion.title));
  };

  const data = useStaticQuery(graphql`
    query {
      craftAPI {
        entry(type: "search") {
          ... on CraftAPI_search_search_Entry {
            id
            componentList {
              ...ComponentListFragment
            }
            shortDescription
            title
          }
        }
      }
    }
  `);

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Dark,
        fontType: "",
      }}
    >
      <MainNavBar theme={Theme.Black} withSearch={false} />

      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
      >
        <Configure hitsPerPage={10} />
        <div className="search-container">
          <ConnectedSearchForm
            client={searchClient}
            onEnter={onSearch}
            onSuggestionSelected={onSuggestionSelected}
            searchTerm={query}
          />
          <SearchFilters />
          <main className="search-body" role="main">
            <div className="col-xs">
              <VirtualSearchBox defaultRefinement={query} />
              <SearchResultsList
                searchPromos={data?.craftAPI?.entry?.componentList}
              />
            </div>
          </main>
        </div>
      </InstantSearch>
    </ThemeContext.Provider>
  );
};

SearchPage.propTypes = {
  location: PropTypes.object,
};

SearchPage.defaultProps = {
  location: {},
};

export default SearchPage;
